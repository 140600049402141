import { FC } from 'react';
import { render as renderRichText } from 'storyblok-rich-text-react-renderer';

import { resizeImage } from 'Utils';

import styles from './Mixed.module.scss';

const Mixed: FC<any> = ({ image, richText, reverseHorizontalLayout }) => {
  return (
    <div className="smartContainer">
      <div className={styles.container} data-alternate-layout={reverseHorizontalLayout}>
        <div className={styles.image}>
          <img src={resizeImage(image)} />
        </div>
        <div className={styles.richText}>
          <div className={styles.text}>{renderRichText(richText)}</div>
        </div>
      </div>
    </div>
  );
};

export { Mixed };
