import Image from 'next/image';
import { FC } from 'react';
import SbEditable from 'storyblok-react';

// import { SliderType } from 'Entities';
import { resizeImage } from 'Utils';

import styles from './Slider.module.scss';

const Slider: FC<any> = ({ sliderItems, ...props }) => {
  console.log({ sliderItems, props });

  return (
    <div className={styles.items}>
      {sliderItems.map((item: any, index: number) => (
        <SbEditable content={item} key={item._uid}>
          <a className={styles.item} key={item._uid} href={item.link}>
            {item.image?.filename && (
              <div className={styles.itemImage}>
                <Image
                  className={styles.itemImage}
                  src={resizeImage(item.image, index % 5 === 0)}
                  layout="fill"
                  unoptimized
                  loading="eager"
                />
              </div>
            )}
          </a>
        </SbEditable>
      ))}
    </div>
  );
};

export { Slider };
