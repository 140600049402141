import axios from 'axios';
import { FC } from 'react';
import useSWR from 'swr';

import styles from './Instagram.module.scss';

const Instagram: FC = () => {
  const { data: response } = useSWR<any>('/api/instagram', axios.get);

  // console.log({ instagram: response });

  const images = response?.data?.graphql?.user.edge_owner_to_timeline_media.edges ?? [];

  return (
    <div className="smartContainer">
      <div className={styles.container}>
        <div className={styles.images}>
          {images.map((image: any) => {
            return (
              <div className={styles.img} key={image.node.thumbnail_src}>
                <img src={image.node.thumbnail_src} alt="" />
              </div>
            );
          })}
        </div>
        <div className={styles.text}>
          <div>Follow me on Instagram to see my most up-to-date work.</div>
          <div>
            <i className="fab fa-instagram" /> mamamist.uk
          </div>
        </div>
      </div>
    </div>
  );
};

export { Instagram };
