import { FC } from 'react';
import { render as renderRichText } from 'storyblok-rich-text-react-renderer';

import { RichTextType } from 'Entities';

import styles from './RichText.module.css';

const RichText: FC<RichTextType> = ({ richText, shouldAlignCenter, addTopGap, addBottomGap }) => {
  console.log({ richText, shouldAlignCenter });

  const style = {
    textAlign: shouldAlignCenter ? 'center' : 'left',
    marginTop: addTopGap ? '80px' : undefined,
    marginBottom: addBottomGap ? '80px' : undefined,
  };

  return (
    <div className="smartContainer" style={style as any}>
      <div className={styles.wrapper}>
      {renderRichText(richText)}

      </div>
    </div>
  );
};

export { RichText };
