import { FC } from 'react';

import { render } from 'storyblok-rich-text-react-renderer';

import { GalleryType } from 'Entities';
import { resizeImage } from 'Utils';

import styles from './Gallery.module.scss';

const Gallery: FC<GalleryType> = ({ images, text }) => {
  const doesTextExist = Boolean(text?.content?.[0]?.content?.[0]);

  return (
    <div className="smartContainer">
      <div className={styles.columns}>
        <div className={styles.column}>
          {images.map((image) => (
            <div className={styles.item} key={image.id}>
              <div className={styles.itemSizing} />
              <img className={styles.itemImage} src={resizeImage(image)} loading="lazy" />
            </div>
          ))}
        </div>

        <div className={styles.column}>
          {images.map((image) => (
            <div className={styles.item} key={image.id}>
              <div className={styles.itemSizing} />
              <img className={styles.itemImage} src={resizeImage(image)} loading="lazy" />
            </div>
          ))}

          {doesTextExist && <div className={styles.text}>{render(text)}</div>}
        </div>

        <div className={styles.column}>
          {images.map((image) => (
            <div className={styles.item} key={image.id}>
              <div className={styles.itemSizing} />
              <img className={styles.itemImage} src={resizeImage(image)} loading="lazy" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Gallery };
