import React, { FC } from 'react';

import { PackagesType } from 'Entities';
import { resizeImage } from 'Utils';

import styles from './Packages.module.scss';

export const Packages: FC<PackagesType> = ({ packages }) => {
  return (
    <div className="smartContainer">
      <div className={styles.container}>
        {packages.map(({ content, price, title, image, _uid }) => {
          return (
            <div className={styles.package} key={_uid}>
              {/* <h2 data-noMarginTop>{title}</h2>
              <div className={styles.price} data-noMarginTop>
                {price}
              </div> */}

              <div className={styles.content}>
                <img className={styles.img} src={resizeImage(image)} loading="eager" />
                <h3>{title}</h3>
                <p>{content}</p>

                <p>{price}</p>
              </div>
              {/* <div className={styles.ball} /> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};
