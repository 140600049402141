import React, { FC } from 'react';
import { storyblokEditable } from '@storyblok/react';

import { Instagram } from 'Components/Instagram';
import { COMPONENT_MAP } from 'Constants';
import { PageType } from 'Entities';

interface PageRendererProps {
  page: PageType;
}

const PageRenderer: FC<PageRendererProps> = ({ page }) => {
  const components = page.content.components ?? [];

  return (
    <>
      {components.map((component) => {
        const Element = COMPONENT_MAP[component.component];

        if (!Element) return null;

        if (component.component === 'slider') {
          return <Element {...component} key={component._uid} />;
        }

        return (
          <div {...storyblokEditable(component)} key={component._uid} data-test="feature">
            <Element {...component} key={component._uid} />
          </div>
        );
      })}

      <Instagram />
    </>
  );
};

export { PageRenderer };
