import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React from 'react';

import { Header } from 'Components';

import '../public/fonts/FontAwesome/css/all.min.css';
import 'Styles/normalize.scss';
import 'Styles/global.scss';
import 'Styles/nprogress.scss';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// eslint-disable-next-line import/no-default-export
export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <script
          crossOrigin="true"
          src="https://polyfill.app/api/polyfill?minify&context=window&features=intersection-observer,scroll-behavior"
        />

        <script
          src="//app.storyblok.com/f/storyblok-latest.js?t=pzV4fS94fnkfaDvS4ieIAgtt"
          type="text/javascript"
        />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap"
          rel="stylesheet"
        />

        <link rel="stylesheet" href="https://use.typekit.net/lxd4rzl.css" />
      </Head>

      <Header />
      <Component {...pageProps} />
    </>
  );
}
