import { FC } from 'react';

import { GalleryType } from 'Entities/Gallery.entity';
import { resizeImage } from 'Utils/ResizeImage';

import styles from './NewGallery.module.css';

export const NewGallery: FC<GalleryType> = ({ images, text }) => {
  return (
    <div className="smartContainer">
      <div className={styles.wrapper}>
        {images.map((image) => (
          <img className={styles.image} src={resizeImage(image)} loading="lazy" />
        ))}
      </div>
    </div>
  );
};
