import StoryblokClient from 'storyblok-js-client';

const publicToken = 'fprowbWydPAj3dJ3rl60lAtt';
const previewToken = 'pzV4fS94fnkfaDvS4ieIAgtt';

const Storyblok = new StoryblokClient({
  accessToken: previewToken,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

const getStoryblokPage = async (pageSlug: string) => {
  const cv = new Date().getTime();
  const version = 'draft'; //hardcoded for now

  return (await Storyblok.get(`cdn/stories/${pageSlug}`, {
    cv,
    version,
    resolve_links: 'url',
  }))?.data;
};

export { Storyblok, getStoryblokPage };
