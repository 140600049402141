import { Gallery, CallToAction, Packages, Sections, RichText, Mixed, Instagram, Slider } from 'Components';
import { NewGallery } from 'Components/NewGallery/NewGallery';

const COMPONENT_MAP = {
  richText: RichText,
  gallery: Gallery,
  gallery: NewGallery,
  callToAction: CallToAction,
  packages: Packages,
  sections: Sections,
  mixed: Mixed,
  instagram: Instagram,
  slider: Slider,
};

export { COMPONENT_MAP };
