import Link from 'next/link';
import { FC } from 'react';

import styles from './CallToAction.module.scss';

const CallToAction: FC<any> = ({ label, url: link }) => {
  return (
    <div className={styles.container}>
      <Link href={link?.story?.url ?? link.url} className={styles.button}>
        {label}
      </Link>
    </div>
  );
};

export { CallToAction };
