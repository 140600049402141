import Link from 'next/link';
import React, { FC } from 'react';

import styles from './Header.module.scss';

export const Header: FC = () => {
  return <>
    <div className={styles.container}>
      {/* <div className={styles.bar}></div> */}

      <Link href="/" className={styles.semi}>
      Mama Mist
      </Link>
    </div>
    <div className={styles.menu}>
      <Link href="/portfolio" className={styles.menuItem}>
        Portfolio
      </Link>
      <Link href="/packages" className={styles.menuItem}>
        Packages
      </Link>
      <Link href="/contact" className={styles.menuItem}>
        About
      </Link>
      <Link href="/contact" className={styles.menuItem}>
        Contact
      </Link>
    </div>
  </>;
};
