import Link from 'next/link';
import React, { FC } from 'react';

import { SectionsType } from 'Entities';
import { resizeImage } from 'Utils';

import styles from './Sections.module.scss';

export const Sections: FC<SectionsType> = ({ sections }) => {
  return (
    <div className="smartContainer">
      <div className={styles.container}>
        {sections.map(({ title, image, _uid, url: link }) => {
          return (
            (<Link
              key={_uid}
              href={link.story?.url ?? link.url}
              className={styles.section}
              key={_uid}>

              <h2 className={styles.title}>{title}</h2>
              <img className={styles.img} src={resizeImage(image)} />

            </Link>)
          );
        })}
      </div>
    </div>
  );
};
